import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { postToBulkUpdateSummarisedFeedbackStatus } from "../../../services/feedback/directorate-officer-feedback-level-service";

import TakeActionRow from "./widgets/TakeActionRow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import handleMutationError from "../../../hooks/handleMutationError";

function TakeActionForm({ selectedTableRowsData, setTableSelectedRows, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToBulkUpdateSummarisedFeedbackStatus,
        onSuccess: () => {
            queryClient.invalidateQueries(["summarised_feedback"]);
            // queryClient.resetQueries(["summarised_feedback"]);
            toast.success("Action Taken Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            setTableSelectedRows([]);
        },
        onError: (error) => {
            handleMutationError(error, setCreactMutationIsLoading);
        },
    });

    const handleSubmit = async (data) => {
        // event.preventDefault();
        setCreactMutationIsLoading(true);

        console.log("take Action by directorate officers : ", data);
        let finalData = {
            status: data?.status,
            comment: data?.comment,
            summarised_feedbacks: selectedTableRowsData,
        };
        console.log("🚀 ~ handleSubmit ~ finalData take Action by directorate officers :: ", finalData);
        creactMutation.mutate(finalData);
    };

    return (
        <Dialog header="Take Action" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <TakeActionRow onSubmit={handleSubmit} productCategoryBrandData={props?.productCategoryBrandData} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default TakeActionForm;
