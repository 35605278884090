import React, { useState } from "react";
import SummarisedFeedbackMapPage from "./summarised-feedback-map/MapPage";
import FeedbackMapPage from "./feedback-map/MapPage"; // Assuming this component exists
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown"; // PrimeReact Dropdown component
import Typewriter from "typewriter-effect";

const MapsPage = ({ loggedInUserData }) => {
    const [selectedMap, setSelectedMap] = useState("feedback");

    const mapOptions = [
        { label: "Feedback Map", value: "feedback" },
        { label: "Summarised Feedback Map", value: "summarised" },
    ];

    const handleMapChange = (e) => {
        setSelectedMap(e.value);
    };

    return (
        <Panel header={`Feedback Map`} toggleable style={{ minWidth: "100%" }}>
            <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
                    <div>
                        <h4>
                            <Typewriter
                                options={{
                                    strings: selectedMap === "feedback" ? "Feedback Map" : "Summarised Feedback Map",
                                    autoStart: true,
                                    loop: false,
                                    cursor: "",
                                    delay: 2,
                                }}
                            />
                        </h4>
                        <p>
                            <Typewriter
                                options={{
                                    strings:
                                        selectedMap === "feedback"
                                            ? "The Feedback Map provides a comprehensive visualization of citizen feedback across Uganda's regions, districts, and localities."
                                            : "The Summarised Feedback Map offers aggregated insights into key feedback metrics, focusing on summarised data from various regions and districts.",
                                    autoStart: true,
                                    loop: false,
                                    cursor: "",
                                    delay: 2,
                                }}
                            />
                        </p>
                    </div>
                    <div>
                        <Dropdown
                            value={selectedMap}
                            options={mapOptions}
                            onChange={handleMapChange}
                            placeholder="Select Map"
                            style={{ width: "200px" }} // Right-align the dropdown
                        />
                    </div>
                </div>

                {/* Conditionally render the selected map */}
                {selectedMap === "feedback" ? <FeedbackMapPage loggedInUserData={loggedInUserData} /> : <SummarisedFeedbackMapPage loggedInUserData={loggedInUserData} />}
            </div>
        </Panel>
    );
};

export default MapsPage;
