import moment from "moment";
import React, { useEffect, useState } from "react";

import { getAllRegions, getRegionsById, postRegions, updateRegions, deleteRegionById } from "../../../services/locations/regions-service";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";
import MuiTable from "../../../components/general_components/MuiTable";
import WaterIsLoading from "../../../components/general_components/WaterIsLoading";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
import handleMutationError from "../../../hooks/handleMutationError";

function RegionList() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [districts, setDistricts] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const columns = [
        {
            title: "Name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
            field: "name",
            render: (rowData) => {
                return (
                    <Link to="districts" state={{ selectedRegion: rowData }} className="text-decoration-none">
                        {rowData.name}
                    </Link>
                );
            },
        },
        {
            title: "Date",
            field: "created_at",
            render: (rowData) => moment(rowData.created_at).format("lll"),
        },
    ];

    const getListOfRegions = useQuery({
        queryKey: ["regions"],
        queryFn: () => getAllRegions(),
    });

    console.log("getListOfRegions : ", getListOfRegions);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfRegions?.isError, getListOfRegions?.error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: deleteRegionById,
        onSuccess: (data) => {
            queryClient.invalidateQueries(["regions"]);
            setLoading(false);
        },
        onError: (error) => {
            handleMutationError(error, setDeleteMutationIsLoading);
        },
    });

    const onFormClose = () => {
        setShowAddForm(false);
    };

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete? ");
    //     if (result === true) {
    //         setLoading(true);
    //         deleteDistrictMutation(id);
    //         setLoading(false);
    //     }
    // };

    const handleDelete = (event, id) => {
        let deleteDistrictId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteDistrictId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteDistrictId) => {
        if (deleteDistrictId !== null) {
            deleteMutation.mutate(deleteDistrictId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    return (
        <div style={{ width: "100%" }}>
            <Panel header="Regions" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <ProgramsCreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => setShowAddForm(true)}>Add Region</Button>

                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div>
                <MuiTable
                    tableTitle="Regions"
                    tableData={getListOfRegions?.data?.data?.data ?? []}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={true}
                    showDelete={true}
                    loading={loading || getListOfRegions.isLoading || getListOfRegions.status == "loading" || deleteMutationIsLoading}
                    exportButton={true}
                    pdfExportTitle="Regions"
                    csvExportTitle="Regions"
                />
                {/* <ConfirmDialog />; */}
                <EditForm districtData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />
            </Panel>
        </div>
    );
}

export default RegionList;
