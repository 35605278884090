import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";

const TakeActionRow = ({ onSubmit }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const validate = (values) => {
        const errors = {};
        if (!values.action) {
            errors.action = "Please select an action";
        }
        if (!values.instructions || values.instructions.trim() === "") {
            errors.instructions = "Please provide instructions";
        }
        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                initialValues={{ action: "aaga" }}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="action" type="radio" component="input" value="aaga">
                            {({ input, meta }) => (
                                <div className="field-radiobutton" style={{ width: "300px" }}>
                                    <RadioButton inputId="aaga" {...input} value="aaga" type="radio" checked={input.checked} onChange={(e) => input.onChange(e)} />
                                    <label htmlFor="aaga" className="p-ml-2">
                                        AAGA
                                    </label>
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Field name="instructions">
                            {({ input, meta }) => (
                                <div className="field">
                                    <label htmlFor="instructions" className="p-d-block">
                                        Instructions
                                    </label>
                                    <InputTextarea id="instructions" {...input} rows={5} cols={30} autoResize />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Button type="submit" label="Submit" className="p-button-primary" />
                    </form>
                )}
            />

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ width: "350px" }}
                modal
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                        <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                <p>Are you sure you want to submit this action?</p>
            </Dialog>
        </div>
    );
};

export default TakeActionRow;
