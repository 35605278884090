import axiosAPI from "../axiosApi";

export async function getAllFeedbacks(params = {}) {
    const response = await axiosAPI.get("feedback", { params: params });
    return response;
}

export async function getFeedbackById(id) {
    const response = await axiosAPI.get(`feedback/` + id);
    return response;
}

export async function postFeedback(data) {
    const response = await axiosAPI.post(`feedback`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToUpdateFeedbackStatus(data) {
    const response = await axiosAPI.post(`update-feedback-status`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkUpdateFeedbackStatus(data) {
    const response = await axiosAPI.post(`bulk-update-feedback-status`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToEditExistingFeedbackStatus(data) {
    const response = await axiosAPI.post(`handle-editing-existing-feedback-status`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkDestroyFeedback(data) {
    const response = await axiosAPI.post(`feedbacks-bulk-delete`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateFeedback(id, data) {
    const response = await axiosAPI.post(`feedback/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteFeedbackById(id) {
    const response = await axiosAPI.delete(`feedback/${id}`);
    return response;
}
