import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

const ServicesFeedback = () => {
    return (
        <div>
            <h2>Services Feedback</h2>
            <p>
                This section provides an overview of feedback related to comments submitted by reporters on OAG services through the Citizen Feedback Platform (CFP) app. Feedback in this section comes from comments submitted by citizens about various services. It is intended to help assess and
                improve service quality. Only authorized personnel with roles in the feedback review process, including
                <strong> AAGC</strong>, <strong>AAGA</strong>, and <strong>AG</strong>, have access to view and act upon this feedback.
            </p>

            <Accordion multiple>
                {/* Reporter */}
                <AccordionTab header="Reporter">
                    <Accordion multiple>
                        <AccordionTab header="Submits Services Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/services_feedback/cfp_app_services_feedback.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>

                <AccordionTab header="AAGC,AAGA,AG">
                    <Accordion multiple>
                        <AccordionTab header="Handles Services Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/services_feedback/services_feedback.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default ServicesFeedback;
