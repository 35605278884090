import React, { useContext, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

import { Dialog } from "primereact/dialog";

import { useNavigate } from "react-router-dom";

//
import { Form, Field } from "react-final-form";
import { Card } from "primereact/card";
import { obtainToken, forgotPassword } from "../../services/auth/auth-api.js";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { css } from "@emotion/react";

const RecoverPassPage = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);

    const containerClassName = classNames("surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden", { "p-input-filled": true });

    //
    const [isLoading, setIsLoading] = useState(false);

    const forgotPasswordMutation = useMutation({
        mutationFn: forgotPassword,
        onSuccess: (data) => {
            setIsLoading(false);
            data?.data?.success ? toast.success(data?.data?.message) : toast.warning(data?.data?.message);
        },
        onError: (error) => {
            setIsLoading(false);
            // toast.error(error?.message ? error?.message : "An error Occured while processing your Email ! please Contact Administrator");
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
        },
    });

    const onSubmit = (values) => {
        setIsLoading(true);
        console.log("Request to reset password for:", values.email);
        forgotPasswordMutation.mutate(values);
        // Here you might call an API to handle the password reset request
    };

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = "Email is invalid";
        }
        return errors;
    };

    return (
        <>
            <div className={containerClassName}>
                <div className="flex flex-column align-items-center justify-content-center">
                    <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                        <Form
                            onSubmit={onSubmit}
                            validate={validate}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} className="p-fluid">
                                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src="assets/oag_photos/uganda_oag.png" alt="logo" style={{ height: "100px" }} />
                                        </div>
                                        <div className="text-center mb-5">
                                            <h3 className="text-600 font-medium">Recover Password</h3>
                                        </div>

                                        <div>
                                            <Field name="email">
                                                {({ input, meta }) => (
                                                    <div className="p-field mb-4">
                                                        <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                                                            Email
                                                        </label>
                                                        <InputText
                                                            {...input}
                                                            id="email"
                                                            type="email"
                                                            className={classNames("block w-full md:w-30rem", {
                                                                "p-invalid": meta.error && meta.touched,
                                                            })}
                                                            style={{ padding: "1rem" }}
                                                        />
                                                        {meta.error && meta.touched && <small className="block p-error">{meta.error}</small>}
                                                    </div>
                                                )}
                                            </Field>

                                            <div className="p-field mb-2">
                                                <Button type="submit" label={isLoading ? <ProgressSpinner style={{ width: "20px", height: "20px" }} strokeWidth="8" /> : "Send Reset Link"} className="w-full p-3 text-xl mb-2" disabled={submitting || isLoading} />
                                            </div>
                                            <div className="p-field">
                                                <p className="p-mt-2">
                                                    Remembered your password?{" "}
                                                    <span style={{ color: "var(--primary-color)", cursor: "pointer" }} onClick={() => navigate("/login")}>
                                                        Login
                                                    </span>
                                                </p>
                                            </div>
                                            {/* <a className="font-medium no-underline ml-2 text-right cursor-pointer " onClick={() => navigate("/sign-up")} style={{ color: "var(--primary-color)" }}>
                                    Or Create a New Account
                                </a> */}
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>

            <Dialog header="Reset Password sent" visible={visible} style={{ width: "50vw" }} onHide={() => setVisible(false)}>
                <p className="m-2">The steps to reset your password has been sent to your email.</p>
                <a className="font-medium no-underline ml-2 text-right cursor-pointer mt--3" onClick={() => navigate("/login")} style={{ color: "var(--primary-color)" }}>
                    Please, try to Login again
                </a>
            </Dialog>
        </>
    );
};

export default RecoverPassPage;
