import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";
import ApprovalForm from "./ApprovalForm";
import ServicesFeedbackDetailsModal from "../global_views/ServicesFeedbackDetailsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllServiceFeedbacks, getServiceFeedbackById, postServiceFeedback, postToBulkDeleteServiceFeedback, updateServiceFeedback, deleteServiceFeedbackById } from "../../services/services-feedback/services-feedback-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import Tooltip from "@mui/material/Tooltip";

import { Tag } from "primereact/tag";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import handleMutationError from "../../hooks/handleMutationError";
function ListPage({ loggedInUserData, spatieStatus, productCategoryBrandData, ...props }) {
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["services-feedback", spatieStatus],
        queryFn: () => getAllServiceFeedbacks({ spatie_current_statuses: [spatieStatus] }),
    });
    console.log("🚀 ~ Feedbacks ListPage ~ data:", data);

    // Use the custom hook to handle errors with useMemo on the error objectgit
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => postToBulkDeleteServiceFeedback(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["services-feedback"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
        onError: (error) => {
            handleMutationError(error, setDeleteMutationIsLoading);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (e, data) => {
        console.log("🚀 ~ handleDelete ~ data:", data);
        // let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(data),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteData) => {
        if (selectedDeleteData !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate({ feedbacks: selectedDeleteData });
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    let tableId = 0;
    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        { title: "Anonymous", field: "is_anonymous", render: (rowData) => (rowData?.is_anonymous ? "Yes" : "No"), hidden: true },
        { title: "Topic", field: "topic", hidden: false },
        {
            title: "Message",
            field: "message",
            render: (rowData) => {
                const maxLength = 10; // Define max length before truncation
                const message = rowData?.message || "No message";
                return message.length > maxLength
                    ? `${message.substring(0, maxLength)}...` // Truncate and add ellipses
                    : message; // Display full message if it's short enough
            },
        },

        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        {
            title: "View",
            field: "created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //
    //table selection
    const primeToast = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [showEvaluteFeedbackForm, setShowEvaluteFeedbackForm] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowEvaluteFeedbackForm = () => {
        if (validateSelectedRows()) {
            setShowEvaluteFeedbackForm(true);
        }
    };

    const handleCloseEvaluteFeedbackForm = () => {
        // setTableSelectedRows([]);
        setShowEvaluteFeedbackForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="New Services Feedback" style={{ marginBottom: "20px" }} toggleable>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("update services feedback status") && tableSelectedRows.length > 0 && (
                        <Button
                            label="Take Action"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowEvaluteFeedbackForm();
                            }}
                        />
                    )}
                    {showEvaluteFeedbackForm && <ApprovalForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showEvaluteFeedbackForm} onHide={handleCloseEvaluteFeedbackForm} onClose={handleCloseEvaluteFeedbackForm} />}

                    {/* {activeUser?.permissions.includes("create feedback") && <Button label="Add Feedback" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} productCategoryBrandData={productCategoryBrandData} /> */}
                </div>

                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    // showEdit={activeUser?.permissions.includes("update feedback")}
                    // handleShowEditForm={handleShowEditForm}
                    handleBulkDelete={(e, item) => handleDelete(e, item)}
                    showBulkDelete={activeUser?.permissions.includes("delete feedback")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle="Services Feedback"
                    csvExportTitle="Services Feedback"
                    //multiple select
                    selection={true}
                    showSelectAllCheckbox={true}
                    showTextRowsSelected={true}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                />

                {showFeedbackModal && <ServicesFeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default ListPage;
