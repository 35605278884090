import React, { useState, useEffect } from "react";

import { getAllSummarisedFeedbacks, getSummarisedFeedbackById, postSummarisedFeedback, postToBulkDestroySummarisedFeedback, updateSummarisedFeedback, deleteSummarisedFeedbackById } from "../../../services/feedback/summarised-feedback-service";

import RowEditForm from "./widgets/RowEditForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import handleMutationError from "../../../hooks/handleMutationError";

function EditForm(props) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateSummarisedFeedback(props?.rowData?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries(["summarised_feedback"]);
            queryClient.invalidateQueries(["feedback"]);
            // // queryClient.resetQueries({ queryKey: "summarised_feedback", exact: true });
            // queryClient.resetQueries(["summarised_feedback"]);
        },
        onError: (error) => {
            setEditMutationIsLoading(false);
            // props.onClose();
            handleMutationError(error, setEditMutationIsLoading);
        },
    });

    // const handleSubmit = (data) => {
    //     console.log(data);

    //     editMutation.mutate(data);
    // };

    const handleSubmit = async (data) => {
        setEditMutationIsLoading(true);
        console.log("Editing rwejecrted data by AAGC Data we are submitting: ", data);

        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("title", data.title);
        formData.append("sector_id", data?.sector?.id);
        formData.append("message", data?.message);
        formData.append("status", data?.status);
        formData.append("latitude", data?.latitude);
        formData.append("longitude", data?.longitude);
        formData.append("feedback_submit_status", data?.feedback_submit_status);
        // formData.append("accuracy", data?.accuracy);
        // formData.append("altitude", data?.altitude);
        // formData.append("speed_accuracy", data?.speed_accuracy);
        formData.append("region_id", data?.region?.id);
        formData.append("district_id", data?.district?.id);
        formData.append("county_id", data?.county?.id);
        formData.append("subcounty_id", data?.subcounty?.id);
        formData.append("parish_id", data?.parish?.id);
        formData.append("village_id", data?.village?.id);

        // Push the whole selectedTableRowsData array without modification
        formData.append("original_feedback", JSON.stringify(data?.original_feedback));

        editMutation.mutate(formData);
    };
    return (
        <Dialog header="Summarise Feedback Edit Form" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <RowEditForm initialData={props.rowData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default EditForm;
