import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { updateQuestion } from "../../services/questions/questions-service";

import QuestionRowForm from "./widgets/QuestionRowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import handleMutationError from "../../hooks/handleMutationError";

function EditQuestion(props) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateQuestion(props?.selectedQuestion?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries(["questions"]);
        },
        onError: (error) => {
            setEditMutationIsLoading(false);
            console.log("Error Response from Backend:", error.response ? error.response.data : error.message);
            // props.onClose();
            handleMutationError(error, setEditMutationIsLoading);
        },
    });

    // useEffect(() => {
    //     if (SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError) {
    //         console.log("🚀 ~ useEffect ~ SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError:", SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error);
    //         SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error?.response?.data?.message
    //             ? toast.error(SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error?.response?.data?.message)
    //             : !SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error?.response
    //             ? toast.warning("Check Your Internet Connection Please")
    //             : toast.error("An Error Occurred Please Contact Admin");
    //     }
    // }, [SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError]);

    const handleSubmit = async (data) => {
        setEditMutationIsLoading(true);
        console.log("Data we are submitting: ", data);

        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("question", data.question);

        props.handleOnClose(data.question);
        // formData.append("photo", data.photo);

        editMutation.mutate(formData);
    };

    return (
        <Dialog header="Edit Question" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <QuestionRowForm initialData={props.selectedQuestion} skip={true} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default EditQuestion;
