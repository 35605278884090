import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import CitizenFeedback from "./CitizenFeedback";
import ServicesFeedback from "./ServicesFeedback";

const UserManualPage = () => {
    return (
        <div className="user-manual">
            <Accordion multiple>
                <AccordionTab header="Citizen Feedback">
                    <CitizenFeedback />
                </AccordionTab>
                <AccordionTab header="Services Feedback">
                    <ServicesFeedback />
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default UserManualPage;
