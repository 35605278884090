import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { getAssignableRoles } from "../../../../services/auth/user-service";

import { addUser } from "../../../../services/auth/auth-api";
import WaterIsLoading from "../../../../components/general_components/WaterIsLoading";
import setFieldTouched from "final-form-set-field-touched";

import { classNames } from "primereact/utils";

import { Password } from "primereact/password";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

//
import { Calendar } from "primereact/calendar";

import { getAllDirectorates, getDirectorateById, postDirectorate, updateDirectorate, deleteDirectorateById } from "../../../../services/directorates/directorates-service";

import { getAllGroupTypes, deleteGroupTypeById } from "../../../../services/grouptypes/grouptypes-service";

import { getAllGroups, deleteGroupById } from "../../../../services/groups/groups-service";

//
import { getAllRegions } from "../../../../services/locations/regions-service";
import { getAllDistricts } from "../../../../services/locations/districts-service";
import { getAllCounty } from "../../../../services/locations/county-service";
import { getAllSubcounty } from "../../../../services/locations/subcounty-service";
import { getAllParish } from "../../../../services/locations/parish-service";
import { getAllVillage } from "../../../../services/locations/village-service";

import useHandleQueryError from "../../../../hooks/useHandleQueryError";

function RowForm({ loggedInUserData, handleSubmit, initialData, ...props }) {
    console.log("loggedInUserData on user list page : ", loggedInUserData);

    console.log("initial data testing lll fdgdsgsdf : ", initialData);

    //============ Auto Complete States =================
    //============ Auto Complete States =================
    const [dropdownRegions, setDropdownRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(initialData?.region);

    const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(initialData?.district);

    const [dropdownCounties, setDropdownCounties] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState(initialData?.county);

    const [dropdownSubcounties, setDropdownSubcounties] = useState([]);
    const [selectedSubcounty, setSelectedSubcounty] = useState(initialData?.subcounty);

    const [dropdownParishes, setDropdownParishes] = useState([]);
    const [selectedParish, setSelectedParish] = useState(initialData?.parish);

    const [dropdownVillages, setDropdownVillages] = useState([]);
    const [selectedVillage, setSelectedVillage] = useState(initialData?.village);

    // Fetch regions
    const regionsQuery = useQuery({
        queryKey: "regions",
        queryFn: getAllRegions,
    });
    useHandleQueryError(regionsQuery?.isError, regionsQuery?.error);

    // Fetch districts based on selected region
    const districtsQuery = useQuery({
        queryKey: ["districts", selectedRegion],
        queryFn: () => getAllDistricts({ region_id: selectedRegion?.id }),
        enabled: !!selectedRegion, // Ensures the query is run only when a region is selected
    });
    useHandleQueryError(districtsQuery?.isError, districtsQuery?.error);

    // Fetch counties based on selected district
    const countiesQuery = useQuery({
        queryKey: ["counties", selectedDistrict],
        queryFn: () => getAllCounty({ district_id: selectedDistrict?.id }),
        enabled: !!selectedDistrict,
    });
    useHandleQueryError(countiesQuery?.isError, countiesQuery?.error);

    // Fetch subcounties based on selected county
    const subcountiesQuery = useQuery({
        queryKey: ["subcounties", selectedCounty],
        queryFn: () => getAllSubcounty({ county_id: selectedCounty?.id }),
        enabled: !!selectedCounty,
    });
    useHandleQueryError(subcountiesQuery?.isError, subcountiesQuery?.error);

    // Fetch parishes based on selected subcounty
    const parishesQuery = useQuery({
        queryKey: ["parishes", selectedSubcounty],
        queryFn: () => getAllParish({ sub_county_id: selectedSubcounty?.id }),
        enabled: !!selectedSubcounty,
    });
    useHandleQueryError(parishesQuery?.isError, parishesQuery?.error);

    // Fetch villages based on selected parish
    const villagesQuery = useQuery({
        queryKey: ["villages", selectedParish],
        queryFn: () => getAllVillage({ parish_id: selectedParish?.id }),
        enabled: !!selectedParish,
    });
    useHandleQueryError(villagesQuery?.isError, villagesQuery?.error);

    //============ Auto Complete States =================
    const [dropdownDirectorates, setDropdownDirectorates] = useState([]);
    const [selectedDirectorate, setSelectedDirectorate] = useState(initialData?.user_directorate?.directorate);

    // ============= Auto Complete States =================
    const [dropdownGroupTypes, setDropdownGroupTypes] = useState([]);
    const [selectedGroupTypes, setSelectedGroupTypes] = useState(initialData?.user_group_types?.map((groupTypeData) => groupTypeData.group_type) ?? []);

    const [dropdownGroups, setDropdownGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState(initialData?.user_groups?.map((groupData) => groupData.group) ?? []);

    //
    const [selectedRole, setSelectedRole] = useState(initialData?.role);

    // Fetch directorates
    const directoratesQuery = useQuery({
        queryKey: "directorates",
        queryFn: getAllDirectorates,
    });
    useHandleQueryError(directoratesQuery?.isError, directoratesQuery?.error);

    // Fetch group types
    // Group Types Query
    const groupTypesQuery = useQuery({
        queryKey: "groupTypes",
        queryFn: getAllGroupTypes,
        enabled: ["CSO", "Group Reporter"].includes(selectedRole), // Fetch group types only if role is CSO
    });
    useHandleQueryError(groupTypesQuery.isError, groupTypesQuery.error);

    // Groups Query (depends on selected group type)
    const groupsQuery = useQuery({
        queryKey: ["groups", selectedGroupTypes],
        queryFn: () => getAllGroups({ groupTypes: selectedGroupTypes }),
        enabled: selectedGroupTypes.length > 0, // Enabled only if group types are selected
    });
    useHandleQueryError(groupsQuery.isError, groupsQuery.error);

    // const onSubmit = (values) => {
    //     createUserMutation.mutate(values);
    // };

    const genderOptions = [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Prefer not to say", value: "prefer not to say" },
    ];

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Name is required";
        }
        if (!values.email) {
            errors.email = "Email is required";
        }
        if (!values.status) {
            errors.status = "Status is required";
        }

        if (!values.gender) {
            errors.gender = "Gender is required";
        }

        // if (!values.date_of_birth) {
        //     errors.date_of_birth = "Date of Birth is required";
        // }
        // Improved Password Validation
        if (!initialData) {
            if (!values.password) {
                errors.password = "Password is required";
            } else {
                if (values.password.length < 8) {
                    errors.password = "Password must be at least 8 characters long";
                }
                if (!/[A-Z]/.test(values.password)) {
                    errors.password = errors.password ? errors.password + " Must include an uppercase letter" : "Must include an uppercase letter";
                }
                if (!/[a-z]/.test(values.password)) {
                    errors.password = errors.password ? errors.password + " Must include a lowercase letter" : "Must include a lowercase letter";
                }
                if (!/[0-9]/.test(values.password)) {
                    errors.password = errors.password ? errors.password + " Must include a number" : "Must include a number";
                }
                if (!/[@$!%*#?&]/.test(values.password)) {
                    errors.password = errors.password ? errors.password + " Must include a special character (@, $, !, %, *, #, ?, &)" : "Must include a special character (@, $, !, %, *, #, ?, &)";
                }
            }
        }

        if (values.role === "Directorate Officer" && !values.directorate) {
            errors.directorate = "Directorate is required";
        }

        if (!values.role) {
            errors.role = "Role is required";
        }

        if (["CSO", "Group Reporter"].includes(values.role) && !values.groupTypes) {
            errors.groupTypes = "Group Type is required";
        }

        if (["CSO", "Group Reporter"].includes(values.role) && !values.groups) {
            errors.groups = "Group is required";
        }
        // You can add more validation logic as needed

        if (values.role === "Reporter" || values.role === "Group Reporter") {
            // Validate 'regions'
            if (!values?.region) {
                errors.region = "Region must be selected";
            }

            // Validate 'districts'
            if (values?.region && !values?.district) {
                errors.district = "District must be selected";
            }

            // Validate 'counties'
            if (values?.district && !values?.county) {
                errors.county = "County must be selected";
            }

            // Validate 'subcounties'
            if (values?.county && !values?.subcounty) {
                errors.subcounty = "Subcounty must be selected";
            }

            // Validate 'parishes'
            if (values?.subcounty && !values?.parish) {
                errors.parish = "Parish must be selected";
            }

            // Validate 'villages'
            if (values?.parish && !values?.village) {
                errors.village = "Village must be selected";
            }
        }

        return errors;
    };

    //   const errors = validate(data);
    //     console.log("🚀 ~ onSubmit ~ errors:", errors);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const onSubmit = (data, form) => {
        console.log("🚀dsfsdes ~ onSubmit ~ data:", data);
        // Add 'form' as an argument
        const errors = validate(data);
        console.log("🚀 ~ onSubmit ~ errors:", errors);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirmSubmit = (values) => {
        handleSubmit(pendingData);
        setShowConfirmDialog(false);
    };

    const onCancelSubmit = () => {
        setShowConfirmDialog(false);
    };

    //==================== get all Roles ====================
    const getAllRolesQuery = useQuery({
        queryKey: ["roles"],
        queryFn: getAssignableRoles,
    });

    useEffect(() => {
        if (getAllRolesQuery?.isError) {
            console.log("Error fetching List of user roles data:", getAllRolesQuery?.error);
            getAllRolesQuery?.error?.response?.data?.message ? toast.error(getAllRolesQuery?.error?.response?.data?.message) : !getAllRolesQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [getAllRolesQuery?.isError]);

    // if (initialData) {
    //     initialData = { role: initialData?.role, directorate: initialData?.user_directorate?.directorate, ...initialData };
    // }

    if (initialData) {
        initialData = {
            role: initialData?.role,
            directorate: initialData?.user_directorate?.directorate,
            groups: initialData?.user_groups?.map((groupData) => groupData.group) ?? [], // Adding groups
            groupTypes: initialData?.user_group_types?.map((groupTypeData) => groupTypeData.group_type) ?? [], // Adding group types
            ...initialData,
        };
    }

    return (
        <div>
            <div className="col-12 md:col-12">
                <div className="card p-fluid">
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialData}
                        validate={validate}
                        initialValuesEqual={() => true}
                        // initialValuesEqual with a function returning true prevents the form from
                        // reinitializing when the initialValues prop changes. This is useful when you
                        // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                        // Be cautious using this if your initial values are meant to be dynamic and responsive
                        // to changes in your application's state.
                        mutators={{ setFieldTouched }}
                        render={({ handleSubmit, form, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="name">Name</label>
                                            <InputText {...input} id="name" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="email">Email</label>
                                            <InputText {...input} id="email" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="phone">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="phone">Phone Number</label>
                                            <InputText {...input} id="phone" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {/* Gender Field */}
                                <Field name="gender">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="gender">Gender</label>
                                            <Dropdown {...input} id="gender" options={genderOptions} placeholder="Select Gender" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {/* Date of Birth Field */}
                                {/* <Field name="date_of_birth">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="date_of_birth">Date of Birth</label>
                                            <Calendar {...input} id="date_of_birth" dateFormat="dd-mm-yy" showIcon className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}

                                <Field name="status">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="status">Status</label>
                                            <Dropdown
                                                {...input}
                                                options={[
                                                    { id: "active", name: "Active" },
                                                    { id: "deactive", name: "Deactive" },
                                                ].map((role) => ({ label: role.name, value: role.id }))}
                                                placeholder="Select a Status"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {["Reporter", "Group Reporter"].includes(selectedRole) && (
                                    <Field name="citizenship">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="citizenship">Citizenship</label>
                                                <Dropdown
                                                    {...input}
                                                    options={[
                                                        { id: "international", name: "International" },
                                                        { id: "local", name: "Local" },
                                                    ].map((citizenship) => ({ label: citizenship.name, value: citizenship.id }))}
                                                    placeholder="Select a Citizenship"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {!initialData && (
                                    <Field name="password">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="password">Password</label>
                                                <Password
                                                    {...input}
                                                    id="password"
                                                    toggleMask
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    feedback={true} // Set to true if you want password strength indicator
                                                    promptLabel="Choose a password"
                                                    weakLabel="Too simple"
                                                    mediumLabel="Average complexity"
                                                    strongLabel="Complex password"
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                <Field name="role">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="role">Role</label>
                                            <Dropdown
                                                {...input}
                                                options={getAllRolesQuery?.data?.data?.map((role) => ({ label: role, value: role })) || []}
                                                placeholder="Select a Role"
                                                disabled={getAllRolesQuery?.isLoading}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    input.onChange(e.value);

                                                    // Function to handle role change
                                                    const handleRoleChange = (role) => {
                                                        setSelectedRole(role);
                                                        if (role !== "Directorate Officer") {
                                                            setSelectedDirectorate(null);
                                                            form.change("directorate", null); // Reset the directorate field in the form
                                                        }

                                                        if (role !== "CSO") {
                                                            setSelectedGroupTypes([]);
                                                            setSelectedGroups([]);
                                                            form.change("groupTypes", null);
                                                            form.change("groups", null);
                                                        }

                                                        form.change("district", null);
                                                        form.change("county", null);
                                                        form.change("subcounty", null);
                                                        form.change("parish", null);
                                                        form.change("village", null);
                                                    };
                                                    handleRoleChange(e.value); // Update role on change
                                                }}
                                            />
                                            {getAllRolesQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {/* Directorate Field */}

                                {/* Conditional Rendering of Directorate Field */}
                                {selectedRole === "Directorate Officer" && (
                                    <Field name="directorate">
                                        {({ input, meta }) => {
                                            const directoratesData = directoratesQuery?.data?.data?.data || [];
                                            const fetchRegionSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = directoratesData.filter((region) => region?.name?.toLowerCase().includes(query));
                                                setDropdownDirectorates(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="directorate">Directorate</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownDirectorates}
                                                        completeMethod={fetchRegionSuggestions}
                                                        field="name"
                                                        value={selectedDirectorate}
                                                        onChange={(e) => {
                                                            setSelectedDirectorate(e.value);
                                                            input.onChange(e.value);
                                                        }}
                                                        dropdown={true}
                                                        disabled={directoratesQuery?.isLoading}
                                                        placeholder="Select Directorate"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if directoratesQuery is loading */}
                                                    {directoratesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                )}

                                {/* Group Type dropdown (only visible if role is CSO) */}

                                {["CSO", "Group Reporter"].includes(selectedRole) && (
                                    <>
                                        <Field name="groupTypes">
                                            {({ input, meta }) => {
                                                const groupTypesData = groupTypesQuery?.data?.data?.data || [];

                                                //=some
                                                // true if the callback function returns a truthy value for at least one element.
                                                // false if the callback function returns false for all elements.

                                                // Filtering for the AutoComplete
                                                const fetchGroupTypes = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = groupTypesData.filter((type) => type?.name?.toLowerCase().includes(query));
                                                    filtered = filtered.filter((groupType) => !selectedGroupTypes.some((selected) => selected.id === groupType.id)); // Exclude selected groupTypes
                                                    setDropdownGroupTypes(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="groupTypes">Group Type</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={true}
                                                            suggestions={dropdownGroupTypes}
                                                            completeMethod={fetchGroupTypes}
                                                            field="name"
                                                            value={selectedGroupTypes}
                                                            onChange={(e) => {
                                                                setSelectedGroupTypes(e.value);
                                                                input.onChange(e.value);

                                                                // Reset groups when group type changes
                                                                setDropdownGroups([]);
                                                                setSelectedGroups([]);

                                                                form.change("groups", null);
                                                            }}
                                                            dropdown={true}
                                                            placeholder="Select Group Type"
                                                            className={meta.touched && meta.error ? "p-invalid" : ""}
                                                        />

                                                        {/* Error message */}
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                        {/* Loading spinner */}
                                                        {groupTypesQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>

                                        {/* Groups dropdown (depends on group type) */}

                                        <Field name="groups">
                                            {({ input, meta }) => {
                                                const groupsData = groupsQuery?.data?.data?.data || [];

                                                // Filtering for the AutoComplete
                                                const fetchGroups = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = groupsData.filter((group) => group?.name?.toLowerCase().includes(query));
                                                    filtered = filtered.filter((group) => !selectedGroups.some((selected) => selected.id === group.id)); // Exclude selected groups
                                                    setDropdownGroups(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="groups">Groups</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={true}
                                                            suggestions={dropdownGroups}
                                                            completeMethod={fetchGroups}
                                                            field="name"
                                                            value={selectedGroups}
                                                            onChange={(e) => {
                                                                setSelectedGroups(e.value);
                                                                input.onChange(e.value);
                                                            }}
                                                            dropdown={true}
                                                            placeholder="Select Groups"
                                                            className={meta.touched && meta.error ? "p-invalid" : ""}
                                                            disabled={groupsQuery.isLoading}
                                                        />

                                                        {/* Error message */}
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                        {/* Loading spinner */}
                                                        {groupsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </>
                                )}

                                {/* Conditional Field Rendering based on the role */}

                                {["Reporter", "Group Reporter"].includes(selectedRole) && (
                                    <>
                                        {/* Region Field */}
                                        <Field name="region">
                                            {({ input, meta }) => {
                                                const regionsData = regionsQuery?.data?.data?.data || [];
                                                const fetchRegionSuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = regionsData.filter((region) => region?.name?.toLowerCase().includes(query));
                                                    setDropdownRegions(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="region">Region</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownRegions}
                                                            completeMethod={fetchRegionSuggestions}
                                                            field="name"
                                                            value={selectedRegion}
                                                            onChange={(e) => {
                                                                setSelectedRegion(e.value);
                                                                input.onChange(e.value);

                                                                // Reset dependent fields
                                                                setSelectedDistrict(null);
                                                                setDropdownDistricts([]);
                                                                setSelectedCounty(null);
                                                                setDropdownCounties([]);
                                                                setSelectedSubcounty(null);
                                                                setDropdownSubcounties([]);
                                                                setSelectedParish(null);
                                                                setDropdownParishes([]);
                                                                setSelectedVillage(null);
                                                                setDropdownVillages([]);

                                                                // Reset form fields for the dependent fields
                                                                form.change("district", null);
                                                                form.change("county", null);
                                                                form.change("subcounty", null);
                                                                form.change("parish", null);
                                                                form.change("village", null);
                                                            }}
                                                            dropdown={true}
                                                            disabled={regionsQuery?.isLoading}
                                                            placeholder="Select region"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                        {/* Show loading spinner if regionsQuery is loading */}
                                                        {regionsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>

                                        <Field name="district">
                                            {({ input, meta }) => {
                                                const districtsData = districtsQuery?.data?.data?.data || [];
                                                const fetchDistrictSuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = districtsData.filter((district) => district?.name?.toLowerCase().includes(query));
                                                    setDropdownDistricts(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="district">District</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownDistricts}
                                                            completeMethod={fetchDistrictSuggestions}
                                                            field="name"
                                                            value={selectedDistrict}
                                                            onChange={(e) => {
                                                                setSelectedDistrict(e.value);
                                                                input.onChange(e.value);

                                                                // Reset dependent fields
                                                                setSelectedCounty(null);
                                                                setDropdownCounties([]);
                                                                setSelectedSubcounty(null);
                                                                setDropdownSubcounties([]);
                                                                setSelectedParish(null);
                                                                setDropdownParishes([]);
                                                                setSelectedVillage(null);
                                                                setDropdownVillages([]);

                                                                // Reset form fields for the dependent fields
                                                                form.change("county", null);
                                                                form.change("subcounty", null);
                                                                form.change("parish", null);
                                                                form.change("village", null);
                                                            }}
                                                            dropdown={true}
                                                            disabled={!selectedRegion}
                                                            placeholder="Select district"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                        {/* Show loading spinner if districtsQuery is loading */}
                                                        {districtsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>

                                        {/* County Field */}
                                        <Field name="county">
                                            {({ input, meta }) => {
                                                const countiesData = countiesQuery?.data?.data?.data || [];
                                                const fetchCountySuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = countiesData.filter((county) => county?.name?.toLowerCase().includes(query));
                                                    setDropdownCounties(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="county">County</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownCounties}
                                                            completeMethod={fetchCountySuggestions}
                                                            field="name"
                                                            value={selectedCounty}
                                                            onChange={(e) => {
                                                                setSelectedCounty(e.value);

                                                                input.onChange(e.value);

                                                                // Reset dependent fields

                                                                setSelectedSubcounty(null);
                                                                setDropdownSubcounties([]);
                                                                setSelectedParish(null);
                                                                setDropdownParishes([]);
                                                                setSelectedVillage(null);
                                                                setDropdownVillages([]);

                                                                // Reset form fields for the dependent fields

                                                                form.change("subcounty", null);
                                                                form.change("parish", null);
                                                                form.change("village", null);
                                                            }}
                                                            dropdown={true}
                                                            disabled={!selectedDistrict || countiesQuery?.isLoading}
                                                            placeholder="Select county"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                        {/* Show loading spinner if regionsQuery is loading */}
                                                        {countiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>

                                        {/* Subcounty Field */}
                                        <Field name="subcounty">
                                            {({ input, meta }) => {
                                                const subcountiesData = subcountiesQuery?.data?.data?.data || [];
                                                const fetchSubcountySuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = subcountiesData.filter((subcounty) => subcounty?.name?.toLowerCase().includes(query));
                                                    setDropdownSubcounties(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="subcounty">Subcounty</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownSubcounties}
                                                            completeMethod={fetchSubcountySuggestions}
                                                            field="name"
                                                            value={selectedSubcounty}
                                                            onChange={(e) => {
                                                                setSelectedSubcounty(e.value);

                                                                input.onChange(e.value);

                                                                // Reset dependent fields

                                                                setSelectedParish(null);
                                                                setDropdownParishes([]);
                                                                setSelectedVillage(null);
                                                                setDropdownVillages([]);

                                                                // Reset form fields for the dependent fields

                                                                form.change("parish", null);
                                                                form.change("village", null);
                                                            }}
                                                            dropdown={true}
                                                            disabled={!selectedCounty || subcountiesQuery?.isLoading}
                                                            placeholder="Select subcounty"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                        {/* Show loading spinner if subcountiesQuery is loading */}
                                                        {subcountiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>

                                        {/* Parish Field */}
                                        <Field name="parish">
                                            {({ input, meta }) => {
                                                const parishesData = parishesQuery?.data?.data?.data || [];
                                                const fetchParishSuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = parishesData.filter((parish) => parish?.name?.toLowerCase().includes(query));
                                                    setDropdownParishes(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="parish">Parish</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownParishes}
                                                            completeMethod={fetchParishSuggestions}
                                                            field="name"
                                                            value={selectedParish}
                                                            onChange={(e) => {
                                                                setSelectedParish(e.value);

                                                                input.onChange(e.value);

                                                                setSelectedVillage(null);
                                                                setDropdownVillages([]);

                                                                // Reset form fields for the dependent fields

                                                                form.change("village", null);
                                                            }}
                                                            dropdown={true}
                                                            disabled={!selectedSubcounty || parishesQuery?.isLoading}
                                                            placeholder="Select parish"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                        {/* Show loading spinner if parishesQuery is loading */}
                                                        {parishesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>

                                        {/* Village Field */}
                                        <Field name="village">
                                            {({ input, meta }) => {
                                                const villagesData = villagesQuery?.data?.data?.data || [];
                                                const fetchVillageSuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = villagesData.filter((village) => village?.name?.toLowerCase().includes(query));
                                                    setDropdownVillages(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="village">Village</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownVillages}
                                                            completeMethod={fetchVillageSuggestions}
                                                            field="name"
                                                            value={selectedVillage}
                                                            onChange={(e) => {
                                                                setSelectedVillage(e.value);
                                                                input.onChange(e.value);
                                                            }}
                                                            dropdown={true}
                                                            disabled={!selectedParish || villagesQuery?.isLoading}
                                                            placeholder="Select village"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                        {/* Show loading spinner if villagesQuery is loading */}
                                                        {villagesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </>
                                )}

                                {/* Add more fields as needed */}
                                <div className="p-field m-4">
                                    <Button type="submit" label="Save" className="p-button-primary" />
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
            <Dialog
                header="Confirm Submission"
                visible={showConfirmDialog}
                style={{ width: "30vw" }}
                onHide={onCancelSubmit}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirmSubmit} />
                        <Button label="No" onClick={onCancelSubmit} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to add this user?
            </Dialog>
        </div>
    );
}

export default RowForm;
