import React, { useEffect, useState } from "react";

import { getAllUsers, getUserById, getApproverRoles, createUser, updateUser, deleteUserById, getAssignableRoles } from "../../../services/auth/user-service";
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";
import WaterIsLoading from "../../../components/general_components/WaterIsLoading";
import moment from "moment";
import { Link } from "react-router-dom";
import MuiTable from "../../../components/general_components/MuiTable";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import UserDetailsPage from "./UserDetailsPage";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
import handleMutationError from "../../../hooks/handleMutationError";

function List({ groupDetails, loggedInUserData }) {
    const queryClient = useQueryClient();
    const [selectedItem, setSelectedItem] = useState({ id: null });

    const [showUserForm, setShowUserForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const [userDetailShowModal, setUserDetailShowModal] = useState(false);
    const [userDetail, setUserDetail] = useState();

    const handleOpenuserDetailModal = (rowData) => {
        setUserDetail(rowData);
        setUserDetailShowModal(true);
    };

    const handleCloseuserDetailModal = () => {
        setUserDetailShowModal(false);
    };

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const handleShowUserForm = () => {
        setShowUserForm(true);
    };
    const handleCloseUserForm = () => {
        setShowUserForm(false);
    };

    const getListOfUsers = useQuery({ queryKey: ["users", "by_group_id", groupDetails?.id], queryFn: () => getAllUsers({ group_id: groupDetails?.id }) });
    console.log("users list : ", getListOfUsers?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfUsers?.isError, getListOfUsers?.error);

    console.log("users list : ", getListOfUsers?.data?.data);

    //
    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: deleteUserById,
        onSuccess: (data) => {
            // queryClient.resetQueries(["users"]);
            setDeleteMutationIsLoading(false);
            console.log("deleted user succesfully ooooo: ");
        },
        onError: (error) => {
            handleMutationError(error, setDeleteMutationIsLoading);
        },
    });

    // const handleDelete = async (event, id) => {
    //     console.log("users is xxxxx : ", id);
    //     var result = window.confirm("Are you sure you want to delete? ");
    //     if (result === true) {
    //         setLoading(true);
    //         deleteUserMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        console.log("Delete Id Is : ", id);

        let deleteUserId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteUserId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteUserId) => {
        if (deleteUserId !== null) {
            console.log("Ohh Yea delete User Id Not Null");
            deleteMutation.mutate(deleteUserId);
        }
    };

    const cancelDelete = () => {
        // setDeleteDirectorateId(null);
    };

    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                tableId = rowData.tableData.id;
                tableId++;
                return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
            render: (rowData) => {
                return (
                    <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleOpenuserDetailModal(rowData)}>
                        {rowData?.name}
                    </span>
                );
            },
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Phone",
            field: "phone",
        },
        {
            title: "Gender", // New column for gender
            field: "gender",
        },
        // {
        //     title: "Date of Birth", // New column for date_of_birth
        //     field: "date_of_birth",
        //     render: (rowData) => {
        //         return moment(rowData?.date_of_birth).format("DD-MM-YYYY"); // Format using moment
        //     },
        // },
        {
            title: "Role",
            field: "role",
        },
        {
            title: "status",
            field: "status",
        },

        {
            title: "lastlogin",
            field: "lastlogin",
            hidden: true,
        },
        {
            title: "Citizenship",
            field: "citizenship",
            hidden: true,
        },
        { title: "Region", field: "region.name", hidden: true },
        { title: "District", field: "district.name", hidden: true },
        { title: "County", field: "county.name", hidden: true },
        { title: "Subcounty", field: "subcounty.name", hidden: true },
        { title: "Parish", field: "parish.name", hidden: true },
        { title: "Village", field: "village.name", hidden: true },
        { title: "Created By", field: "created_by.name", hidden: true },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Created At",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            <Panel header="Group Members" style={{ marginBottom: "20px" }}>
                <div>
                    <div xs={12}>
                        <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                            {/* {loggedInUserData?.permissions?.includes("create user") && (
                                <div>
                                    <div md={12} className="text-end">
                                        <Button onClick={() => handleShowUserForm()}>Add user</Button>
                                    </div>
                                </div>
                            )} */}
                        </div>
                        <div>
                            <div>
                                <MuiTable
                                    tableTitle="Group Members"
                                    tableData={getListOfUsers?.data?.data?.data ?? []}
                                    tableColumns={columns}
                                    // handleShowEditForm={handleShowEditForm}
                                    // handleDelete={(e, item_id) => handleDelete(e, item_id)}
                                    // showEdit={loggedInUserData?.permissions?.includes("update user")}
                                    // showDelete={loggedInUserData?.permissions?.includes("delete user")}
                                    loading={getListOfUsers.isLoading || getListOfUsers.status == "loading" || deleteMutationIsLoading}
                                />

                                <UserDetailsPage user={userDetail} showModal={userDetailShowModal} handleCloseModal={handleCloseuserDetailModal} />
                                <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} loggedInUserData={loggedInUserData} />
                                <CreateForm show={showUserForm} onHide={handleCloseUserForm} onClose={handleCloseUserForm} loggedInUserData={loggedInUserData} />
                                {/* {(getListOfUsers.isLoading || getListOfUsers.status == "loading") && <WaterIsLoading />} */}
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default List;
