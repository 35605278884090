import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllSummarisedFeedbacks, getSummarisedFeedbackById, postSummarisedFeedback, postToBulkDestroySummarisedFeedback, updateSummarisedFeedback, deleteSummarisedFeedbackById } from "../../../services/feedback/summarised-feedback-service";

import RowForm from "./widgets/RowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import handleMutationError from "../../../hooks/handleMutationError";

function CreateForm({ selectedTableRowsData, setTableSelectedRows, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postSummarisedFeedback,
        onSuccess: () => {
            queryClient.invalidateQueries(["feedback"]);
            // queryClient.invalidateQueries(["summarised_feedback"]);
            toast.success("Feedback Summarised Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            // setTableSelectedRows([]);
            // selectedTableRowsData([])
        },
        onError: (error) => {
            handleMutationError(error, setCreactMutationIsLoading);
        },
    });

    const handleSubmit = async (data) => {
        console.log("🚀 ~ creating summarised feedback handleSubmit ~ data:", data);

        setCreactMutationIsLoading(true);

        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("sector_id", data?.sector?.id);
        formData.append("message", data?.message);
        formData.append("status", data?.status);
        formData.append("feedback_submit_status", data?.feedback_submit_status ?? "submitted");
        formData.append("latitude", data?.latitude);
        formData.append("longitude", data?.longitude);

        formData.append("region_id", data?.region?.id);
        formData.append("district_id", data?.district?.id);
        formData.append("county_id", data?.county?.id);
        formData.append("subcounty_id", data?.subcounty?.id);
        formData.append("parish_id", data?.parish?.id);
        formData.append("village_id", data?.village?.id);
        formData.append("village_id", data?.village?.id);

        // Push the whole selectedTableRowsData array without modification
        formData.append("original_feedback", JSON.stringify(selectedTableRowsData));

        creactMutation.mutate(formData);
    };

    return (
        <Dialog header="Summarise Feedback Create Form" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Fill in the form below</p>
            <RowForm handleSubmit={handleSubmit} initialData={Array.isArray(selectedTableRowsData) && selectedTableRowsData.length > 0 ? selectedTableRowsData[0] : null} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default CreateForm;
