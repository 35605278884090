import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";

import setFieldTouched from "final-form-set-field-touched";

import { getAllGroups } from "../../../services/groups/groups-service";
import { getAllRegions } from "../../../services/locations/regions-service";
import { getAllDistricts } from "../../../services/locations/districts-service";
import { getAllCounty } from "../../../services/locations/county-service";
import { getAllSubcounty } from "../../../services/locations/subcounty-service";
import { getAllParish } from "../../../services/locations/parish-service";
import { getAllVillage } from "../../../services/locations/village-service";
//
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "primereact/fileupload";

function RowForm({ handleSubmit, productCategoryBrandData, initialData, ...props }) {
    console.log("🚀df ~ RowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();
    const [targetAudience, setTargetAudience] = useState(null);
    const [geography, setGeography] = useState(null);
    const [selectedRegionsIds, setSelectedRegionsIds] = useState([]);
    const [selectedDistrictsIds, setSelectedDistrictsIds] = useState([]);
    const [selectedCountiesIds, setSelectedCountiesIds] = useState([]);
    const [selectedSubcountiesIds, setSelectedSubcountiesIds] = useState([]);
    const [selectedParishesIds, setSelectedParishesIds] = useState([]);
    const [selectedVillagesIds, setSelectedVillagesIds] = useState([]);
    const [gender, setGender] = useState(null);

    // Regions Query (depends on selected channel)
    const groupsQuery = useQuery({
        queryKey: ["all-groups"],
        queryFn: () => getAllGroups(),
    });

    const regionsQuery = useQuery({
        queryKey: ["all-regions"],
        queryFn: () => getAllRegions(),
    });

    useEffect(() => {
        if (!regionsQuery?.isLoading) {
            console.log("Regions Query:", regionsQuery?.data?.data?.data);
        }
    }, [regionsQuery?.isLoading]);

    const districtsQuery = useQuery({
        queryKey: ["region-districts", selectedRegionsIds],
        queryFn: () => getAllDistricts({ region_ids: selectedRegionsIds }),
        enabled: selectedRegionsIds.length > 0,
    });
    const countiesQuery = useQuery({
        queryKey: ["district-counties", selectedDistrictsIds],
        queryFn: () => getAllCounty({ district_ids: selectedDistrictsIds }),
        enabled: selectedDistrictsIds.length > 0,
    });
    const subcountiesQuery = useQuery({
        queryKey: ["county-subcounties", selectedCountiesIds],
        queryFn: () => getAllSubcounty({ county_ids: selectedCountiesIds }),
        enabled: selectedCountiesIds.length > 0,
    });
    const parishesQuery = useQuery({
        queryKey: ["subcounty-parishes", selectedSubcountiesIds],
        queryFn: () => getAllParish({ sub_county_ids: selectedSubcountiesIds }),
        enabled: selectedSubcountiesIds.length > 0,
    });
    const villagesQuery = useQuery({
        queryKey: ["parish-villages", selectedParishesIds],
        queryFn: () => getAllVillage({ parish_ids: selectedParishesIds }),
        enabled: selectedParishesIds.length > 0,
    });

    const validate = (values) => {
        const errors = {};

        if (!values.title) errors.title = "Title is required";

        if (!values.description) errors.description = "Description is required";

        if (!values.target_audience) {
            errors.target_audience = "Target Audience is required";
        }

        if (targetAudience === "geographical" && !values.geographical) {
            errors.geographical = "geographical location type is required";
        }

        if ((geography === "regions" || geography === "districts" || geography === "counties" || geography === "subcounties" || geography === "parishes" || geography === "villages") && !values.regions) {
            errors.regions = "Atleast one region is required";
        }

        if ((geography === "districts" || geography === "counties" || geography === "subcounties" || geography === "parishes" || geography === "villages") && !values.districts) {
            errors.districts = "Atleast one district is required";
        }

        if ((geography === "counties" || geography === "subcounties" || geography === "parishes" || geography === "villages") && !values.counties) {
            errors.counties = "Atleast one County is required";
        }

        if ((geography === "subcounties" || geography === "parishes" || geography === "villages") && !values.subcounties) {
            errors.subcounties = "Atleast one SubCounty is required";
        }

        if ((geography === "parishes" || geography === "villages") && !values.parishes) {
            errors.parishes = "Atleast one parish is required";
        }

        if (geography === "villages" && !values.villages) {
            errors.villages = "Atleast one village is required";
        }

        if (targetAudience === "groups" && !values.groups) {
            errors.groups = "Atleast one group is required";
        }

        if (!values.startDate) {
            errors.startDate = "Select Start Date";
        }

        if (!values.endDate) {
            errors.endDate = "Select End Date";
        }
        // if (!values.price) errors.price = "Price is required";
        // if (!values.quantity) errors.quantity = "Quantity is required";

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            var rType = "all";
            var ids = [];
            if (pendingData.target_audience === "geographical") {
                rType = pendingData.geographical;
                if (rType === "regions") {
                    ids = selectedRegionsIds;
                }
                if (rType === "districts") {
                    ids = selectedDistrictsIds;
                }
                if (rType === "counties") {
                    ids = selectedCountiesIds;
                }
                if (rType === "subcounties") {
                    ids = selectedSubcountiesIds;
                }
                if (rType === "parishes") {
                    ids = selectedParishesIds;
                }
                if (rType === "villages") {
                    ids = selectedVillagesIds;
                }
            }
            if (pendingData.target_audience === "international") {
                var rType = "international";
            }
            if (pendingData.target_audience === "groups") {
                var rType = "groups";
                ids = pendingData.groups;
            }
            const respondents = {
                type: rType, // "geographical" in this case
                ids: ids, // Assuming ids come from the regions field
                gender: pendingData.gender ?? null, // Set gender to null
            };

            // Add the respondents key to pendingData
            const updatedPendingData = {
                ...pendingData,
                respondents: respondents,
            };

            console.log("pending Data zed", updatedPendingData);
            handleSubmit(updatedPendingData); // Submit updated data
            setPendingData(null);

            // console.log("pending Data zed", pendingData);
            // handleSubmit(pendingData);
            // setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="title">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="title">Title</label>
                                        <InputText {...input} id="title" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="price">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="price">Price</label>
                                        <InputText {...input} id="price" type="number" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="quantity">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="quantity">Quantity</label>
                                        <InputText {...input} id="quantity" type="number" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            {/* <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="name" type="text" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="description">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="description" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="description">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="description" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="target_audience">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="target_audience">Target Audience</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { id: "all", name: "All Informers" },
                                                { id: "geographical", name: "Geographical" },
                                                { id: "groups", name: "Groups" },
                                                { id: "international", name: "International Informers" },
                                            ].map((role) => ({ label: role.name, value: role.id }))}
                                            placeholder="Select a Target Audience"
                                            value={targetAudience}
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setTargetAudience(e.value); // Update state when selection changes
                                                setGeography(null);
                                                setSelectedRegionsIds([]);
                                                setSelectedDistrictsIds([]);
                                                setSelectedCountiesIds([]);
                                                setSelectedSubcountiesIds([]);
                                                setSelectedParishesIds([]);
                                            }}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {targetAudience && (
                                <Field name="gender">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="gender">Gender</label>
                                            <div className="p-formgroup-inline" style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                                                <div className="p-field-radiobutton">
                                                    <RadioButton
                                                        {...input}
                                                        inputId="male"
                                                        value="male"
                                                        checked={gender === "male"}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);
                                                            setGender(e.value);
                                                        }}
                                                    />
                                                    <label htmlFor="male">Male</label>
                                                </div>

                                                <div className="p-field-radiobutton">
                                                    <RadioButton
                                                        {...input}
                                                        inputId="female"
                                                        value="female"
                                                        checked={gender === "female"}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);
                                                            setGender(e.value);
                                                        }}
                                                    />
                                                    <label htmlFor="female">Female</label>
                                                </div>

                                                <div className="p-field-radiobutton">
                                                    <RadioButton
                                                        {...input}
                                                        inputId="null"
                                                        value={null}
                                                        checked={gender === null}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);
                                                            setGender(e.value);
                                                        }}
                                                    />
                                                    <label htmlFor="null">Both</label>
                                                </div>
                                            </div>
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {targetAudience === "geographical" && (
                                <Field name="geographical">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="geographical">Geographical Location Type</label>
                                            <Dropdown
                                                {...input}
                                                options={[
                                                    { id: "regions", name: "Regions" },
                                                    { id: "districts", name: "Districts" },
                                                    { id: "counties", name: "Counties" },
                                                    { id: "subcounties", name: "Subcounties" },
                                                    { id: "parishes", name: "Parishes" },
                                                    { id: "villages", name: "Villages" },
                                                ].map((role) => ({ label: role.name, value: role.id }))}
                                                placeholder="Select a Geographical Location Type"
                                                className={classNames({ "p-invalid": targetAudience !== "geographical" || (meta.touched && meta.error) })}
                                                value={geography}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setGeography(e.value); // Update state for tracking selection
                                                    setSelectedRegionsIds([]);
                                                    setSelectedDistrictsIds([]);
                                                    setSelectedCountiesIds([]);
                                                    setSelectedSubcountiesIds([]);
                                                    setSelectedParishesIds([]);
                                                }}
                                            />
                                            {targetAudience !== "geographical" || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {targetAudience === "groups" && (
                                <Field name="groups">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="groups">Groups</label>
                                            <MultiSelect
                                                {...input}
                                                options={(groupsQuery?.data?.data?.data || []).map((role) => ({ label: role.name, value: role.id }))}
                                                placeholder="Select groups"
                                                className={classNames({ "p-invalid": targetAudience !== "groups" || (meta.touched && meta.error) })}
                                                // value={geography}
                                                disabled={groupsQuery?.isLoading}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setGeography(null); // Update state for tracking selection
                                                    setSelectedRegionsIds([]);
                                                    setSelectedDistrictsIds([]);
                                                    setSelectedCountiesIds([]);
                                                    setSelectedSubcountiesIds([]);
                                                    setSelectedParishesIds([]);
                                                }}
                                            />
                                            {targetAudience !== "groups" || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {(geography === "regions" || geography === "districts" || geography === "counties" || geography === "subcounties" || geography === "parishes" || geography === "villages") && (
                                <Field name="regions">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="regions">Regions</label>
                                            <MultiSelect
                                                {...input}
                                                // options={[
                                                //     { id: "all", name: "All Informers" },
                                                //     { id: "geographical", name: "Geographical" },
                                                //     { id: "groups", name: "Groups" },
                                                // ].map((role) => ({ label: role.name, value: role.id }))}
                                                options={(regionsQuery?.data?.data?.data || []).map((region) => ({
                                                    label: region.name, // Use region name as the label
                                                    value: region.id, // Use region id as the value
                                                }))}
                                                // options={regionsQuery?.data?.data?.data || []}
                                                placeholder="Select Regions"
                                                className={classNames({ "p-invalid": (geography !== "regions" && geography !== "districts" && geography !== "counties" && geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error) })}
                                                disabled={regionsQuery?.isLoading}
                                                value={selectedRegionsIds}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setSelectedRegionsIds(e.value); // Update state for tracking selection
                                                    setSelectedDistrictsIds([]);
                                                    setSelectedCountiesIds([]);
                                                    setSelectedSubcountiesIds([]);
                                                    setSelectedParishesIds([]);
                                                }}
                                            />
                                            {(geography !== "regions" && geography !== "districts" && geography !== "counties" && geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {(geography === "districts" || geography === "counties" || geography === "subcounties" || geography === "parishes" || geography === "villages") && (
                                <Field name="districts">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="districts">Districts</label>
                                            <MultiSelect
                                                {...input}
                                                // options={[
                                                //     { id: "all", name: "All Informers" },
                                                //     { id: "geographical", name: "Geographical" },
                                                //     { id: "groups", name: "Groups" },
                                                // ].map((role) => ({ label: role.name, value: role.id }))}
                                                options={(districtsQuery?.data?.data?.data || []).map((district) => ({
                                                    label: district.name, // Use region name as the label
                                                    value: district.id, // Use region id as the value
                                                }))}
                                                // options={regionsQuery?.data?.data?.data || []}
                                                placeholder="Select Districts"
                                                className={classNames({ "p-invalid": (geography !== "districts" && geography !== "counties" && geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error) })}
                                                disabled={districtsQuery?.isLoading && selectedRegionsIds.length > 0}
                                                value={selectedDistrictsIds}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setSelectedDistrictsIds(e.value); // Update state for tracking selection
                                                    setSelectedCountiesIds([]);
                                                    setSelectedSubcountiesIds([]);
                                                    setSelectedParishesIds([]);
                                                }}
                                            />
                                            {(geography !== "districts" && geography !== "counties" && geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {(geography === "counties" || geography === "subcounties" || geography === "parishes" || geography === "villages") && (
                                <Field name="counties">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="counties">Counties</label>
                                            <MultiSelect
                                                {...input}
                                                // options={[
                                                //     { id: "all", name: "All Informers" },
                                                //     { id: "geographical", name: "Geographical" },
                                                //     { id: "groups", name: "Groups" },
                                                // ].map((role) => ({ label: role.name, value: role.id }))}
                                                options={(countiesQuery?.data?.data?.data || []).map((counties) => ({
                                                    label: counties.name, // Use region name as the label
                                                    value: counties.id, // Use region id as the value
                                                }))}
                                                // options={regionsQuery?.data?.data?.data || []}
                                                placeholder="Select Counties"
                                                className={classNames({ "p-invalid": (geography !== "counties" && geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error) })}
                                                disabled={countiesQuery?.isLoading && selectedDistrictsIds.length > 0}
                                                value={selectedCountiesIds}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setSelectedCountiesIds(e.value); // Update state for tracking selection
                                                    setSelectedSubcountiesIds([]);
                                                    setSelectedParishesIds([]);
                                                }}
                                            />
                                            {(geography !== "counties" && geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {(geography === "subcounties" || geography === "parishes" || geography === "villages") && (
                                <Field name="subcounties">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="subcounties">SubCounties</label>
                                            <MultiSelect
                                                {...input}
                                                // options={[
                                                //     { id: "all", name: "All Informers" },
                                                //     { id: "geographical", name: "Geographical" },
                                                //     { id: "groups", name: "Groups" },
                                                // ].map((role) => ({ label: role.name, value: role.id }))}
                                                options={(subcountiesQuery?.data?.data?.data || []).map((subcounties) => ({
                                                    label: subcounties.name, // Use region name as the label
                                                    value: subcounties.id, // Use region id as the value
                                                }))}
                                                // options={regionsQuery?.data?.data?.data || []}
                                                placeholder="Select SubCounties"
                                                className={classNames({ "p-invalid": (geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error) })}
                                                disabled={subcountiesQuery?.isLoading && selectedCountiesIds.length > 0}
                                                value={selectedSubcountiesIds}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setSelectedSubcountiesIds(e.value); // Update state for tracking selection
                                                    setSelectedParishesIds([]);
                                                }}
                                            />
                                            {(geography !== "subcounties" && geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {(geography === "parishes" || geography === "villages") && (
                                <Field name="parishes">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="parishes">Parishes</label>
                                            <MultiSelect
                                                {...input}
                                                // options={[
                                                //     { id: "all", name: "All Informers" },
                                                //     { id: "geographical", name: "Geographical" },
                                                //     { id: "groups", name: "Groups" },
                                                // ].map((role) => ({ label: role.name, value: role.id }))}
                                                options={(parishesQuery?.data?.data?.data || []).map((parishes) => ({
                                                    label: parishes.name, // Use region name as the label
                                                    value: parishes.id, // Use region id as the value
                                                }))}
                                                // options={regionsQuery?.data?.data?.data || []}
                                                placeholder="Select Parishes"
                                                className={classNames({ "p-invalid": (geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error) })}
                                                disabled={parishesQuery?.isLoading && selectedSubcountiesIds.length > 0}
                                                value={selectedParishesIds}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setSelectedParishesIds(e.value); // Update state for tracking selection
                                                    setSelectedVillagesIds([]);
                                                }}
                                            />
                                            {(geography !== "parishes" && geography !== "villages") || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            {geography === "villages" && (
                                <Field name="villages">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="villages">Villages</label>
                                            <MultiSelect
                                                {...input}
                                                // options={[
                                                //     { id: "all", name: "All Informers" },
                                                //     { id: "geographical", name: "Geographical" },
                                                //     { id: "groups", name: "Groups" },
                                                // ].map((role) => ({ label: role.name, value: role.id }))}
                                                options={(villagesQuery?.data?.data?.data || []).map((villages) => ({
                                                    label: villages.name, // Use region name as the label
                                                    value: villages.id, // Use region id as the value
                                                }))}
                                                // options={regionsQuery?.data?.data?.data || []}
                                                placeholder="Select Villages"
                                                className={classNames({ "p-invalid": geography !== "villages" || (meta.touched && meta.error) })}
                                                disabled={villagesQuery?.isLoading && selectedSubcountiesIds.length > 0}
                                                value={selectedVillagesIds}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Sync with form
                                                    setSelectedVillagesIds(e.value); // Update state for tracking selection
                                                }}
                                            />
                                            {geography !== "villages" || (meta.touched && meta.error && <small className="p-error">{meta.error}</small>)}
                                        </div>
                                    )}
                                </Field>
                            )}

                            <Field name="startDate">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="startDate">Start Date</label>
                                        <InputText {...input} type="datetime-local" id="startDate" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="endDate">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="endDate">End Date</label>
                                        <InputText {...input} type="datetime-local" id="endDate" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
