import React, { useState, useEffect, useMemo } from "react";
import UgandaGeoJson from "./widgets/resources/GeoJsonFiles/uganda.json";
import CountriesGeoJson from "./widgets/resources/GeoJsonFiles/countries.json";
import { MapContainer, Map, TileLayer, useMap, Marker, Popup, GeoJSON, LayersControl } from "react-leaflet";
import * as L from "leaflet";

// import MapFilters from "../MapFilters/MapFilters";
import { ProgressBar } from "primereact/progressbar";
import "leaflet-easyprint";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import MapPrint from "./widgets/MapPrint";
import Typewriter from "typewriter-effect";

//
import FiltersForm from "./FiltersForm";
import { Button } from "primereact/button";

//
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllSummarisedFeedbacks, getSummarisedFeedbackById } from "../../../../services/feedback/summarised-feedback-service";

//
import useHandleQueryError from "../../../../hooks/useHandleQueryError";
import handleMutationError from "../../../../hooks/handleMutationError";

//
//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/oag-lotties/material-ui-loading-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/oag-lotties/snail-error-lottie.json";
import SateLiteLottie from "../../../../assets/lotties/oag-lotties/satelite-loading-lottie.json";
import Lottie from "lottie-react";

//
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//
import FeedbackAttachments from "./widgets/FeedbackAttachments";
import moment from "moment";
import CountUp from "react-countup";
import { Tag } from "primereact/tag";

//
// Fix icon issue in Leaflet 1.x
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function MapPage({ loggedInUserData }) {
    //
    const { BaseLayer, Overlay } = LayersControl;
    // filters
    const [showFilters, setShowFilters] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
        // startDate: moment().startOf("month").toDate(),
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
    });

    console.log("🚀 ~ MapPage ~ selectedFilters:", selectedFilters);

    // Function to toggle the dialog visibility
    const toggleFilterDialog = () => {
        setShowFilters((prevShow) => !prevShow);
    };

    const handleSubmitFilters = (data) => {
        setSelectedFilters(data);
    };

    //  queryFn: () => getAllSummarisedFeedbacks({ spatie_current_statuses: ["draft", "submitted", "new"] }),
    const queryClient = useQueryClient();
    const getSummarisedFeedbackQuery = useQuery({
        queryKey: ["summarised_feedback", "selceted_filters", selectedFilters],
        queryFn: () => getAllSummarisedFeedbacks(selectedFilters),
    });

    console.log("🚀 ~Maps Page Summarised Feedbacks getSummarisedFeedbackQuery ~ data: ", getSummarisedFeedbackQuery?.data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getSummarisedFeedbackQuery?.isError, getSummarisedFeedbackQuery?.error);

    //============ create joejson ==================

    const memorisedSelectedFiltersData = useMemo(() => selectedFilters, [selectedFilters]);

    const [filteredDistrictsGeoJson, setFilteredDistrictsGeoJson] = useState(null);
    const [filteredRegionsGeoJson, setFilteredRegionsGeoJson] = useState(null);

    // Function to filter GeoJSON data based on the provided districts
    const filterGeoJsonForDistricts = (districts, isProject = false) => {
        return {
            ...UgandaGeoJson,
            features: UgandaGeoJson.features
                .filter((feature) => districts.some((district) => district.name.toLowerCase() === feature.properties.name.toLowerCase()))
                .map((feature) => {
                    if (isProject) {
                        // Assign a random color for project districts
                        // const colorIndex = Math.floor(Math.random() * colors.length);
                        // feature.properties.fillColor = colors[colorIndex];
                    }
                    return feature;
                }),
        };
    };

    // Effect to update GeoJSON data when waterPointsData changes
    useEffect(() => {
        //=======show regions sketch if no districts =========================
        if (!memorisedSelectedFiltersData?.districts || (Array.isArray(memorisedSelectedFiltersData.districts) && memorisedSelectedFiltersData.districts.length === 0)) {
            if (memorisedSelectedFiltersData?.regions && Array.isArray(memorisedSelectedFiltersData?.regions) && memorisedSelectedFiltersData?.regions?.length > 0) {
                // Loop through the regions and extract the districts
                const allDistricts = memorisedSelectedFiltersData.regions.flatMap((region) => region.districts || []);

                // Pass the collected districts to filterGeoJsonForDistricts
                const newFilteredGeoJson = filterGeoJsonForDistricts(allDistricts);

                // Set the filtered districts GeoJSON
                setFilteredRegionsGeoJson(newFilteredGeoJson);
            }
        } else {
            setFilteredRegionsGeoJson(null);
        }

        //=======show districts sketch sketch if no districts =========================
        if (memorisedSelectedFiltersData?.districts && Array.isArray(memorisedSelectedFiltersData?.districts) && memorisedSelectedFiltersData?.districts?.length > 0) {
            const newFilteredGeoJson = filterGeoJsonForDistricts(memorisedSelectedFiltersData?.districts);
            setFilteredDistrictsGeoJson(newFilteredGeoJson);
        } else {
            setFilteredDistrictsGeoJson(null);
        }
    }, [memorisedSelectedFiltersData]);

    const getSummarisedFeedbackSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Corporate Management":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };

    //
    const createMarkerPopup = (feedback) => (
        <div style={{ height: "300px", overflow: "scroll" }}>
            <p>
                <strong>Title:</strong> {feedback?.title}
            </p>
            <p>
                <strong>Feedback No:</strong> {feedback?.feedback_no}
            </p>

            <p>
                <strong>Status:</strong>
                <Tag value={feedback?.spatie_current_status?.name ?? "Unknown"} severity={getSummarisedFeedbackSeverityColor(feedback?.spatie_current_status?.name)} />
            </p>
            <p>
                <strong>Message:</strong>
                <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{feedback?.message}</span>
            </p>
            <p>
                <strong>Sector:</strong> {feedback?.sector?.name}
            </p>
            <p>
                <strong>Region:</strong> {feedback?.region?.name}
            </p>
            <p>
                <strong>District:</strong> {feedback?.district?.name}
            </p>
            <p>
                <strong>County:</strong> {feedback?.county?.name}
            </p>
            <p>
                <strong>Subcounty:</strong> {feedback?.subcounty?.name}
            </p>
            <p>
                <strong>Parish:</strong> {feedback.parish.name}
            </p>
            <p>
                <strong>Village:</strong> {feedback?.village?.name}
            </p>

            <p>
                <strong>Latitude:</strong> {feedback?.latitude}
            </p>
            <p>
                <strong>Longitude:</strong> {feedback?.longitude}
            </p>

            <div>
                <FeedbackAttachments originalFeedbacksData={feedback?.original_feedbacks} />
            </div>
        </div>
    );

    const onEachDistrict = (feature, layer) => {
        const districtName = feature.properties.name.toLowerCase();
        const districtFeedbacks = getSummarisedFeedbackQuery.data?.data?.data.filter((feedback) => feedback.district.name.toLowerCase() === districtName);

        if (districtFeedbacks && districtFeedbacks.length > 0) {
            layer.bindPopup(`
                <h4>${feature.properties.name}</h4>
                <p><strong>Number of Feedbacks:</strong> ${districtFeedbacks.length}</p>
            `);
        }
    };

    //
    const handleMapCreated = (map) => {
        map.options.zoomDelta = 5;
        map.options.wheelPxPerZoomLevel = 12;
    };

    //
    // Style for project districts (with colors)
    const geoJsonDistrictStyles = (feature) => ({
        weight: 2,
        opacity: 1,
        color: "black",
        fillColor: feature.properties.fillColor,
        fillOpacity: 0.1,
    });

    return (
        <div>
            <div style={{ margin: "1rem", display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "1rem" }}>
                <p>
                    <Tag className="mr-2" icon="pi pi-check" severity="success">
                        Total Feedback : <CountUp end={Array.isArray(getSummarisedFeedbackQuery?.data?.data?.data) ? getSummarisedFeedbackQuery?.data?.data?.data?.length : 0} />
                    </Tag>
                </p>
                <div>
                    {/* Filter button with filter icon */}
                    <Button label="Filters" icon="pi pi-filter" onClick={toggleFilterDialog} className="p-button-primary" />

                    {/* Filters dialog */}
                    <FiltersForm selectedFilters={selectedFilters} show={showFilters} onHide={toggleFilterDialog} handleSubmit={handleSubmitFilters} />
                </div>
            </div>

            {getSummarisedFeedbackQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "100%" }}>
                            <Lottie animationData={SateLiteLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : getSummarisedFeedbackQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div>
                        <MapContainer center={[1.3733, 32.2903]} zoom={7} scrollWheelZoom={true} attributionControl={false} fullscreenControl={true} whenCreated={handleMapCreated} style={{ height: "80vh", width: "100%", zIndex: "0" }}>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
                            <LayersControl>
                                <BaseLayer checked name="OpenStreetMap">
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                </BaseLayer>

                                <BaseLayer name="Terrain View">
                                    <TileLayer url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                                </BaseLayer>

                                <BaseLayer name="Satellite View">
                                    <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                                </BaseLayer>
                                <BaseLayer name="Hybrid View">
                                    <TileLayer url="https://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                                </BaseLayer>
                            </LayersControl>

                            {filteredDistrictsGeoJson && <GeoJSON data={filteredDistrictsGeoJson} onEachFeature={onEachDistrict} style={geoJsonDistrictStyles} />}
                            {filteredRegionsGeoJson && <GeoJSON data={filteredRegionsGeoJson} onEachFeature={onEachDistrict} style={geoJsonDistrictStyles} />}

                            <MarkerClusterGroup chunkedLoading>
                                {getSummarisedFeedbackQuery.data?.data?.data.map((feedback, index) => {
                                    // Parse latitude and longitude values
                                    const latitude = parseFloat(feedback?.latitude);
                                    const longitude = parseFloat(feedback?.longitude);

                                    // Check if latitude and longitude are valid numbers before creating the Marker
                                    if (isNaN(latitude) || isNaN(longitude)) {
                                        console.warn(`Summarised Feedback map Skipping marker at index ${index} due to invalid coordinates: (${feedback.latitude}, ${feedback.longitude})`);
                                        return null; // Skip this marker
                                    }

                                    return (
                                        <Marker key={index} position={[latitude, longitude]}>
                                            <Popup>{createMarkerPopup(feedback)}</Popup>
                                        </Marker>
                                    );
                                })}
                            </MarkerClusterGroup>

                            <MapPrint />
                        </MapContainer>
                    </div>
                </>
            )}
        </div>
    );
}

export default MapPage;
