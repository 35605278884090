import React, { useContext, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";

//
import { Form, Field } from "react-final-form";
import { Card } from "primereact/card";

import { css } from "@emotion/react";

import { obtainToken, forgotPassword } from "../../services/auth/auth-api.js";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";

import { postThirdPartyRegisterAuth, postThirdPartyLoginAuth } from "../../services/auth/auth-api.js";

import { Fieldset } from "primereact/fieldset";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import { FaGoogle } from "react-icons/fa";

const LoginPage = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [googleLoginIsLoading, setGoogleLoginIsLoading] = useState(false);

    const loginMutation = useMutation({
        mutationFn: (variables) => obtainToken(variables?.email, variables?.password),
        onSuccess: (data) => {
            console.log("successfull login : xxxxx data : ", data);
            setIsLoading(false);
            queryClient.invalidateQueries([]);
            //   axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
            navigate("/");
            // window.location.reload();
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            setIsLoading(false);

            console.log("login error : ", error);
        },
    });

    const onSubmit = (values) => {
        setIsLoading(true);
        console.log("Login data:", values);
        loginMutation.mutate(values);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            // errors.email = "Email / Phone Number is required";
            errors.email = "Email is required";
        }
        // else if (!/\S+@\S+\.\S+/.test(values.email)) {
        //   errors.email = "Email is invalid";
        // }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    //Third party auth

    const thirdPartyLoginAuthMutation = useMutation({
        mutationFn: (variables) => postThirdPartyLoginAuth(variables),
        onSuccess: (data) => {
            console.log("postThirdPartyAuth data : ", data);
            setIsLoading(false);
            queryClient.invalidateQueries([]);
            //   axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
            navigate("/");
            // window.location.reload();
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            setIsLoading(false);

            console.log("login error : ", error);
        },
    });

    // const handleLoginWithGoogle = useGoogleLogin({
    //   onSuccess: async (response) => {
    //     //
    //     try {
    //       setGoogleLoginIsLoading(false);
    //       console.log(response);
    //       // let responseDecoded = jwtDecode(response?.access_token);
    //       // console.log("🚀 ~ Login ~ responseDecoded:", responseDecoded);
    //       const res = await axios.get(
    //         "https://www.googleapis.com/oauth2/v3/userinfo",
    //         {
    //           headers: {
    //             Authorization: `Bearer ${response?.access_token}`
    //           }
    //         }
    //       );
    //       console.log("🚀 ~ google Login onSuccess: ~ res:", res);
    //     } catch (err) {
    //       setGoogleLoginIsLoading(false);
    //       console.log("🚀 ~ google Login ~ err:", err);
    //     }
    //   }
    // });

    //
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [checked, setChecked] = useState(false);

    const containerClassName = classNames("surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden", { "p-input-filled": true });

    const runLogin = () => {
        localStorage.setItem("username", email);
        navigate("/dashboard");
    };

    const onClickForgotPass = () => {
        navigate("/reset-password");
    };

    const onClickCreateAccount = () => {
        navigate("/sign-up");
    };

    return (
        <div className={containerClassName}>
            <div className="flex flex-column align-items-center justify-content-center">
                <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="assets/oag_photos/uganda_oag.png" alt="logo" style={{ height: "100px" }} />
                        </div>
                        <div className="text-center mb-5">
                            <h3 className="text-900 font-medium">Sign in</h3>
                        </div>

                        <Form
                            onSubmit={onSubmit}
                            validate={validate}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} className="p-fluid">
                                    <div>
                                        {/* <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                            Username
                                        </label>
                                        <InputText inputid="email1" type="text" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full md:w-30rem mb-5" style={{ padding: "1rem" }} /> */}

                                        <Field name="email">
                                            {({ input, meta }) => (
                                                <div className="p-field mb-5">
                                                    {/* <label htmlFor="email">Email / Phone Number</label> */}
                                                    <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                                                        Email
                                                    </label>
                                                    <InputText
                                                        id="email"
                                                        {...input}
                                                        type="text"
                                                        className={classNames("block w-full md:w-30rem", {
                                                            "p-invalid": meta.error && meta.touched,
                                                        })}
                                                        inputClassName="w-full p-3 md:w-30rem"
                                                    />
                                                    {meta.error && meta.touched && <small className="block p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="password">
                                            {({ input, meta }) => (
                                                <div className="p-field mb-5">
                                                    <label htmlFor="password">Password</label>
                                                    <Password
                                                        id="password"
                                                        {...input}
                                                        toggleMask
                                                        type="password"
                                                        className={classNames("block w-full md:w-30rem", {
                                                            "p-invalid": meta.error && meta.touched,
                                                        })}
                                                        inputClassName="w-full p-3 md:w-30rem"
                                                    />
                                                    {meta.error && meta.touched && <small className="block p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>

                                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                            <div className="flex align-items-center">
                                                <Checkbox inputid="rememberme1" checked={checked} onChange={(e) => setChecked(e.checked)} className="mr-2"></Checkbox>
                                                <label htmlFor="rememberme1">Remember me</label>
                                            </div>
                                            <a
                                                href="/"
                                                className="font-medium no-underline ml-2 text-right cursor-pointer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onClickForgotPass();
                                                }}
                                                style={{ color: "var(--primary-color)" }}
                                            >
                                                Forgot password?
                                            </a>
                                        </div>

                                        <Button type="submit" label={isLoading ? <ProgressSpinner style={{ width: "20px", height: "20px" }} strokeWidth="8" /> : "Login"} className="w-full p-3  text-xl mb-4" disabled={submitting || isLoading} />
                                        {/* <a className="font-medium no-underline ml-2 text-right cursor-pointer " onClick={() => onClickCreateAccount()} style={{ color: "var(--primary-color)" }}>
                                Or Create a New Account
                            </a> */}
                                    </div>
                                </form>
                            )}
                        />

                        {/* Google login */}
                        {/* <Fieldset legend="Or Continue With">
                            <div className="m-1 p-field">
                                <GoogleLogin
                                    onSuccess={async (response) => {
                                        console.log(response);
                                        let responseDecoded = jwtDecode(response?.credential);
                                        console.log("🚀 ~ Login ~ responseDecoded:", responseDecoded);
                                        let dataToPost = {
                                            name: responseDecoded?.name,
                                            picture: responseDecoded?.picture,
                                            client_id: response?.clientId,
                                            provider: "google",
                                            email: responseDecoded?.email,
                                        };
                                        thirdPartyLoginAuthMutation.mutate(dataToPost);
                                    }}
                                    onError={() => {
                                        console.log("Login Failed");
                                    }}
                                />
                                //had commented this
                                <Button
                                    type="button"
                                    label={googleLoginIsLoading ? <ProgressSpinner style={{ width: "20px", height: "20px" }} strokeWidth="8" /> : "Google"}
                                    icon={<FaGoogle />}
                                    className="p-mt-2"
                                    severity="warning"
                                    text
                                    raised
                                    onClick={() => {
                                        setGoogleLoginIsLoading(true);
                                        handleLoginWithGoogle();
                                    }}
                                    disabled={googleLoginIsLoading}
                                />
                                //had commented this
                            </div>
                        </Fieldset> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
